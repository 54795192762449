<template>
	<div style="display:flex;height:100%;">
		<div style="width:390px;height:100%;border:1px solid #333;">
			<iframe :src="webUrl" width="100%" height="100%" border="1"></iframe>
		</div>
		<div>
			<el-input placeholder="请输入URL" v-model="webUrl" class="">
				<el-button slot="append" icon="el-icon-search"></el-button>
			</el-input>
		</div>
	</div>

</template>

<script>
	export default {
		data() {
			return {
				// webUrl:"https://localhost:5100/Main/Home",
				webUrl: "https://appwms.hytx.com/",
				listUrl: [
					{
						webUrlKey:"47.252.36.4",
						pdaUrl: "http://47.252.36.4:86/",
					},
					{
						webUrlKey:"123.56.233.109",
						pdaUrl: "http://123.56.233.109:86/",
					},
					{	
						webUrlKey:"admin.warehouse.hytx.com",
						pdaUrl: "https://appwms.hytx.com/",
					},
					{
						webUrlKey:"usadmin.warehouse.hytx.com",
						pdaUrl: "https://usappwms.hytx.com/",
					},
				]
			}
		},
		activated() {

		},
		created() {
			this.initUrl();
		},
		mounted() {

		},
		methods: {
			initUrl() {
				let currentWeb = this.listUrl.find(item=>{
					return item.webUrlKey==window.location.hostname;
				});
				if(currentWeb && currentWeb.pdaUrl){
					this.webUrl = currentWeb.pdaUrl;
				}
			}
		}
	}
</script>

<style>
</style>